import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Form from "./Form"

export default function ContactSection({ city }) {
  return (
    <div className="relative flex border-y border-gray-300 bg-gray-100 py-2">
      <div className="z-10 hidden lg:absolute lg:inset-0 lg:block">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <div className="h-56 w-full object-cover lg:absolute lg:h-60">
            <StaticImage
              src="../../../assets/images/contact-section/hand.png"
              alt="Анализатор катализаторов"
            />
          </div>
        </div>
      </div>
      <div className="z-30 py-0 px-4 sm:py-6 sm:px-6 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8 lg:py-12">
        <div className="lg:pr-8">
          <Form city={city} />
        </div>
      </div>
    </div>
  )
}
