import img1 from "../../assets/images/catalog/ford-transit.jpg"
import img2 from "../../assets/images/catalog/Mercedes-Benz Sprinter Classic.jpg"

export const sajCars = [
  {
    link: "/sazhevyie-filtry/sazhevyij-filtr-ford-transit",
    name: "Ford Transit",
    year: "2019",
    engine: "2.2",
    type: "дизель",
    img: img1,
    price: "1 800",
    date: "02.11.2021",
  },
  {
    link: "/sazhevyie-filtry/katalizator-mercedes-benz-sprinter-classic",
    name: "Mercedes-Benz Sprinter Classic",
    year: "2014",
    engine: "2.2",
    type: "дизель",
    img: img2,
    price: "4 560",
    date: "31.08.2021",
  },
]
